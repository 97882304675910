<template>
  <v-card
    no-gutter
    :style="
      'width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
    class="hide-scrollbar"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module.title }}</v-toolbar-title
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="tags"
      :items-per-page="10"
      :single-expand="true"
      item-key="deveui"
      class="elevation-1"
      style="overflow-y: auto; padding-bottom: 3rem; background: transparent"
      dense
      @click:row="(row, slot) => getCurrents(slot)"
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-icon dark :color="getIconColor(hasRecentData(item))">{{
          hasRecentData(item)
        }}</v-icon>
      </template>
      <template v-slot:[`item.battery`]="{ item }">
        {{ batteryLevel(item) }}
      </template>
      <template v-slot:[`item.rssi`]="{ item }">
        {{ getRSSI(item) }}
      </template>
      <template v-slot:[`item.alarm`]="{ item }">
        <v-icon dark :color="alarmActive(item)">timer</v-icon>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ lastDataTime(item) }}
      </template>
      <template v-slot:expanded-item>
        <td :colspan="headers.length + 1" class="px-0">
          <v-data-table
            :headers="[
              {},
              { text: $t('tagdata.fields.key'), value: 'label' },
              { text: $t('tag.fields.latestValue'), value: 'value' },
            ]"
            :items="currents"
            item-key="key"
            hide-default-footer
            disable-sort
            dense
            @click:row="clickedRow"
            disable-pagination
          >
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <TagHistoryDialog
        :dialogTrigger="dialogTrigger"
        :dialog="dialog"
        v-on:dialog-toggle="dialog = !dialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TagHistoryDialog from "@/components/common/TagHistoryDialog";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "SensorOverviewList",
  mixins: [Meta],
  props: ["module"],

  data() {
    return {
      search: "",
      expanded: false,
      dialog: false,
      deleting: false,
      tags: [],
      dialogTrigger: null,
      currentsData: [],
      headers: [
        { text: this.$t("tag.fields.name"), value: "name" },
        { text: this.$t("tag.fields.battery"), value: "battery" },
        {
          text: "RSSI",
          value: "rssi",
        },

        {
          text: this.$t("tag.fields.latestValue"),
          value: "createdAt",
          dataType: "Date",
        },

        {
          text: this.$t("tag.fields.activeData"),
          value: "active",
          sortable: false,
        },

        {
          text: this.$t("tag.fields.activeAlarm"),
          value: "alarm",
          filterable: false,
          sortable: false,
        },
        { text: "", value: "action", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany", "hasCompany"]),
    ...mapState("companies", ["status", "companyApps"]),
    ...mapState("tag", {
      tagStatus: "status",
      decoders: "decoders",
    }),
    ...mapState("tagData", { tagDataStatus: "status", currents: "currents" }),
    ...mapState("alarms", { alarms: "alarms" }),

    keysForTag(deveui) {
      return this.tags.filter((t) => {
        t.deveui == deveui;
      });
    },
  },

  methods: {
    ...mapActions("tag", {
      getTags: "getTagsWithInformation",
      getDecoders: "getDecoders",
    }),
    ...mapActions("alarms", ["getAlarms"]),
    ...mapActions("tagData", ["getCurrentsForTag"]),

    async getCurrents(slot) {
      await this.getCurrentsForTag(slot.item.deveui);
      slot.expand(!slot.isExpanded);
    },

    alarmActive(item) {
      let match = false;
      this.alarms.forEach((alarm) => {
        if (
          alarm.advanced == false &&
          alarm.tag != null &&
          alarm.tag.deveui == item.deveui &&
          match == false &&
          alarm.activeAlarms.length > 0
        )
          match = true;

        if (
          alarm.advanced == true &&
          alarm.advancedAlarms.length > 0 &&
          match == false &&
          alarm.advancedAlarms.find(
            (d) => d.device.deveui.toUpperCase() == item.deveui.toUpperCase()
          ) != null
        )
          match = true;
      });

      if (match) {
        return "error";
      }
      return "grey";
    },

    batteryLevel(item) {
      if (item.battery) {
        var value = parseFloat(item.battery).toFixed(2);
        var suffix = " V";
        if (value > 1000) suffix = " mV";

        return value + suffix;
      } else return "N/A";
    },

    getRSSI(item) {
      if (item.rssi) return parseInt(item.rssi).toFixed(0) + " dBm";
      else return "N/A";
    },

    RSSIColor(item) {
      if (item.data && item.data.length > 0)
        item.data.forEach((d) => {
          if (d.key == "rssi") {
            let rssiValue = d.value;

            if (rssiValue > -80) return "green--text";
            else if (rssiValue > -105) return "light-green--text";
            else if (rssiValue > -115) return "orange--text";
            else return "red--text";
          }
        });
    },

    hasRecentData(item) {
      if (item.lastContact) {
        if (this.timeWithinHours(item.lastContact, 24)) return "mdi-check";
        else return "mdi-sleep";
      } else return "mdi-help";
    },

    lastDataTime(item) {
      if (item.lastContact) {
        return this.humanDate(item.lastContact, "dd MMM yyyy - HH:mm");
      }
      return "N/A";
    },

    getIconColor(iconName) {
      switch (iconName) {
        case "mdi-sleep":
          return "yellow";
        case "mdi-help":
          return "error";
        case "mdi-battery-unknown":
          return "grey";
        case "mdi-battery-50":
          return "yellow";
        case "mdi-battery-10":
          return "error";
        default:
          return "success";
      }
    },

    clickedRow(caller) {
      this.dialogTrigger = caller;
      this.dialog = true;
    },
  },

  async mounted() {
    if (this.hasCompany && this.currentUser) {
      this.tags = await this.getTags();

      await this.getAlarms();
    }
  },

  components: {
    TagHistoryDialog,
  },
};
</script>
<style></style>
