<template>
  <generic-selector
    v-model="localTag"
    item-key="deveui"
    item-label="name"
    :items="tags"
    :title="$t('tag.choose')"
  />
</template>

<script>
import GenericSelector from "./GenericSelector.vue";
import { mapActions } from "vuex";

export default {
  name: "NodeSelector",
  components: { GenericSelector },

  props: {
    value: {
      default: undefined,
    },
  },

  model: { prop: "value" },

  data() {
    return {
      tags: [],
    };
  },

  computed: {
    localTag: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    ...mapActions("tag", ["getTagsWithInformation"]),
  },

  async mounted() {
    this.tags = await this.getTagsWithInformation();
  },
};
</script>
